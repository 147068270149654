import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  height?: string,
  style?: CSSProperties,
}

const Scroller = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      children,
      height,
      style,
    } = props;

    const styles: CSSProperties = {
      overflowY: 'auto',
      height: height ?? '604px',
      padding: '8px',
      ...style,
    };

    return (
      <div ref={ref} style={styles}>
        {children}
      </div>
    );
  },
);

export default Scroller;
