import * as Yup from 'yup';
/* eslint-disable import/no-extraneous-dependencies */
import { t } from 'src/lib/language';
import { PAYMENT_STATUS, PAYMENT_TYPE } from 'src/models/PaymentModel';
import moment from 'moment';

const isOnlinePayment = (
  type: PAYMENT_TYPE,
) => [PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(type);
const isOfflinePayment = (
  type: PAYMENT_TYPE,
) => ![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(type);

const AdminDonationValidator = (lang:string) => Yup.object().shape({
  paid: Yup.number()
    .moreThan(0, t(lang, 'validations.invoice.field_required'))
    .required(),
  quantity: Yup.number()
    .moreThan(0.01, t(lang, 'validations.invoice.field_required'))
    .required(),
  contact_id: Yup.number()
    .integer()
    .required(),
  bank_account_id: Yup.number()
    .when('payment_type', {
      is: (value: PAYMENT_TYPE) => isOnlinePayment(value),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .min(1, t(lang, 'validations.invoice.field_required'))
    .integer(),
  product_id: Yup.number()
    .min(1, t(lang, 'validations.invoice.field_required'))
    .integer()
    .required(),
  payment_gateway_id: Yup.number()
    .when('payment_type', {
      is: (value: PAYMENT_TYPE) => isOnlinePayment(value),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .integer(),
  seller_id: Yup.number()
    .integer(),
  scheduled_date: Yup.date()
    .nullable()
    .when(['payment_status', 'payment_type'], {
      is: (
        status: PAYMENT_STATUS,
        type: PAYMENT_TYPE,
      ) => isOfflinePayment(type) && status === PAYMENT_STATUS.scheduled,
      then: (schema) => schema.min(moment().startOf('day')).required(),
    })
    .when('payment_type', {
      is: (value: PAYMENT_TYPE) => isOnlinePayment(value),
      then: (schema) => schema.min(moment().startOf('day')).required(),
    }),
  paid_date: Yup.date()
    .when(['payment_type', 'payment_status'], {
      is: (
        type: PAYMENT_TYPE,
        status: PAYMENT_STATUS,
      ) => isOfflinePayment(type) && status === PAYMENT_STATUS.paid,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  start_date: Yup.date()
    .when('is_installment', {
      is: (value: boolean) => value,
      then: (schema) => schema.min(moment().startOf('day')).required(),
      otherwise: (schema) => schema.nullable(),
    }),
  processed_date: Yup.date()
    .nullable(),
  payment_info_id: Yup.number()
    .when('payment_type', {
      is: (value: PAYMENT_TYPE) => isOnlinePayment(value),
      then: (schema) => schema.min(1).required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

export default AdminDonationValidator;
