import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  cancelInvoicesData,
  getInvoiceData,
  getInvoiceGuessData,
  postInvoiceData,
  putInvoiceData,
} from 'src/apis/InvoiceAPI';
import { normalizeProductListData } from 'src/apis/ProductAPI';
import { getOrganisationData, normalizeOrganisationData } from 'src/apis/OrganisationAPI';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setAlert } from 'src/redux/actions/alertActions';
import { setError } from 'src/redux/actions/errorsActions';
import { getEntitiesAction } from 'src/redux/actions/querybuilderActions';
import { getContactAction } from 'src/redux/actions/contactActions';
import { setProductListData } from 'src/redux/actions/productAction';
import { setCurrentOrganisation } from 'src/redux/actions/organisationActions';
import { resetSelections, setSelections } from 'src/redux/actions/selectionActions';
import { setFormStatus } from 'src/redux/actions/formActions';
import { closeModal } from 'src/redux/actions/modalActions';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { setTaxesData } from 'src/redux/actions/taxActions';
import { InvoiceModel, InvoicePayload } from 'src/models/InvoiceModel';
import { MODALS } from 'src/models/ModalModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { DIALOGS } from 'src/models/DialogModel';
import { TaxItemModel } from 'src/models/TaxListModel';
import moment from 'moment-timezone-all';
// eslint-disable-next-line import/no-extraneous-dependencies

export const SET_INVOICE = 'SET_INVOICE';
export const RESET_INVOICE = 'RESET_INVOICE';
export const SET_INVOICES = 'SET_INVOICES';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const setInvoice = makeActionCreator(SET_INVOICE);
export const setInvoices = makeActionCreator(SET_INVOICES);
export const resetInvoices = makeActionCreator(RESET_INVOICE);
export const updateInvoiceAction = makeActionCreator(UPDATE_INVOICE);

export const normalizeInvoice = (data: any) => {
  const invoice = { ...data } as InvoiceModel;
  invoice['invoices.due_date'] = moment(data['invoices.due_date']);
  invoice['invoices.issued_date'] = !!invoice['invoices.issued_date']
    ? moment(invoice['invoices.issued_date'])
    : undefined;
  return invoice;
};

export const normalizeInvoiceTaxes = (taxes: TaxItemModel[]) => taxes.reduce((accu, tax) => {
  const current = { ...accu };
  current[tax.id] = tax;
  return current;
}, {} as { [key: number]: TaxItemModel });

export const postInvoiceAction:
(payload: InvoicePayload, close: boolean) => ThunkedAction<State> =
  (payload: InvoicePayload, close) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.contact]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const invoiceData = {
        ...payload,
        invoice: payload.invoice,
      };
      const response = await postInvoiceData(organisation, invoiceData);
      const { data, success } = response;
      if (success) {
        dispatch(setFormStatus({ [FORM.add_invoice]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.invoices_saved',
          id: 'invoice-saved',
        }));
        dispatch(updateInvoiceAction({
          id: response.data.id,
          invoice: normalizeInvoice(data),
        }));
        if (close) {
          dispatch(closeModal({ modal: MODALS.addInvoice }));
        }
        dispatch(getEntitiesAction(ENTITIES.invoices));
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.add_invoice]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.add_invoice]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: Object.values(response.errors.fields)[0],
        }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.invoice_saved_error',
        }));
        dispatch(setFormStatus({ [FORM.reset_password]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getInvoiceAction: (id: number) => ThunkedAction<State> =
(id: number) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getInvoiceData(id, organisation);

    const { data, success } = response;
    if (success) {
      data.hasInstallments = data.installments > 0;
      const invoice = {
        ...normalizeInvoice(data),
        taxes: data.taxes,
      };
      dispatch(updateInvoiceAction({
        id: data.id,
        invoice,
      }));
      if (invoice.contact_id) {
        await getContactAction(invoice.contact_id);
      }
      dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.error }));
  }
};

export const getInvoiceGuessAction: (
  invoiceHash: string, organisation: string) => ThunkedAction<State, number> =
(invoiceHash: string, organisation: string) => async (dispatch: any) => {
  if (!invoiceHash) {
    return 0;
  }
  try {
    dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.processing }));
    const organisationResponse = await getOrganisationData(organisation);
    if (organisationResponse.success) {
      const organisationData = normalizeOrganisationData(organisationResponse.data);
      dispatch(setCurrentOrganisation(organisationData));
    }
    const response = await getInvoiceGuessData(invoiceHash, organisation);
    const { data, success } = response;
    if (success) {
      dispatch(updateInvoiceAction({
        id: data.id,
        invoice: {
          ...normalizeInvoice(data),
          taxes: normalizeInvoiceTaxes(data.taxes),
        },
      }));
      dispatch(setTaxesData({ data: data.taxes }));
      dispatch(setProductListData(normalizeProductListData(data.products)));
      dispatch(setSelections({
        entity: ENTITIES.invoices,
        selections: [data.id],
      }));
      dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.success }));
      return data.id;
    }
    dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.error }));
    return 0;
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.error }));
    return 0;
  }
};

export const cancelInvoiceAction:
(id:number) => ThunkedAction<State> =
    (id:number) => async (dispatch: any, getState: any) => {
      try {
        dispatch(setFormStatus({ [FORM.cancel_invoice]: FORM_STATUS.processing }));
        const organisation = getState().currentOrganisation.id;
        const response = await cancelInvoicesData(id, organisation);
        if (response.success) {
          dispatch(setAlert({
            type: ALERT_TYPE.success,
            code: 'messages.invoices_cancelled',
          }));
          dispatch(getEntitiesAction(ENTITIES.invoices));
          dispatch(closeDialog({ dialog: DIALOGS.cancelInvoice }));
          dispatch(setFormStatus({ [FORM.cancel_invoice]: FORM_STATUS.success }));
          dispatch(resetSelections({ entity: ENTITIES.invoices }));
        } else if (response.status === 400) {
          dispatch(setError({ [FORM.cancel_invoice]: response.message }));
          dispatch(setFormStatus({ [FORM.cancel_invoice]: FORM_STATUS.error }));
          dispatch(setAlert({
            type: ALERT_TYPE.error,
            code: response.message,
          }));
        }
      } catch (e) { /* Log the error here */
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.invoices_cancelled_error',
        }));
        dispatch(setFormStatus({ [FORM.cancel_invoice]: FORM_STATUS.error }));
      }
    };

export const putInvoiceAction:
(id:number, invoice: InvoicePayload) => ThunkedAction<State> =
  (id:number, invoice: InvoicePayload) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.put_invoice]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await putInvoiceData(id, organisation, invoice);
      const { data, success } = response;
      if (success) {
        dispatch(setFormStatus({ [FORM.put_invoice]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.invoice_updated',
          id: 'popup-invoice-updated',
        }));
        dispatch(updateInvoiceAction({
          id,
          invoice: normalizeInvoice(data),
        }));
        dispatch(getEntitiesAction(ENTITIES.invoices));
        dispatch(closeModal({ modal: MODALS.editInvoice }));
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.put_invoice]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.put_invoice]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: Object.values(response.errors.fields)[0],
        }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.contact_updated_error',
        }));
        dispatch(setFormStatus({ [FORM.put_invoice]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.contact_updated_error',
      }));
    }
  };
